import React, { useState } from 'react'
import { Text, Anchor, Modal, TextInput, Button, Group, PasswordInput, Checkbox } from '@mantine/core'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { addUser } from '../../../firebase/firestoreService' // Adjust the import path as needed
import TermsAndConditions from './../../../components/TermsAndConditions/TermsAndConditions'

const RegisterForm = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [_error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const navigate = useNavigate()
  const auth = getAuth()
  const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

  const handleRegister = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError('')
    if (!firstName || !lastName || !email) {
      notifications.show({
        title: 'Missing Information',
        message: 'Please enter all fields.',
        color: 'red'
      })
    } else if (!passwordCriteria.test(password)) {
      // Password doesn't meet the criteria
      // Handle the error, e.g., show a message to the user
      notifications.show({
        title: 'Invalid Password',
        message:
          'The password must be at least 8 characters long and include uppercase and lowercase letters, a number, and a special character.',
        color: 'red'
      })
    } else if (password !== confirmPassword) {
      notifications.show({
        title: 'Password Mismatch',
        message: 'The confirmed password does not match the original password. Please try again.',
        color: 'red'
      })
    } else {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        await addUser(userCredential.user.uid, firstName, lastName, email)
        notifications.show({
          title: 'Registration Successful',
          message: 'You have successfully registered.',
          color: 'green'
        })
        await signInWithEmailAndPassword(auth, email, password)
        navigate('/projects')
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: error.message,
          color: 'red'
        })
      }
    }
    setPassword('') // Clear the password field
    setConfirmPassword('') // Clear the confirm password field
    setIsLoading(false)
  }

  return (
    <>
      <TextInput
        className='authInput'
        label='First Name'
        placeholder='Your first name'
        required
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextInput
        className='authInput'
        label='Last Name'
        placeholder='Your last name'
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        mt='md'
      />
      <TextInput
        className='authInput'
        label='Email'
        placeholder='Your Email'
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        mt='md'
      />
      <PasswordInput
        className='authInput'
        label='Password'
        placeholder='Your password'
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        mt='md'
      />
      <PasswordInput
        className='authInput'
        label='Confirm Password'
        placeholder='Confirm password'
        required
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        mt='md'
      />
      <Checkbox
        label='I agree to the Terms and Conditions'
        checked={hasAgreedToTerms}
        onChange={(event) => setHasAgreedToTerms(event.currentTarget.checked)}
        required
        mt='md'
      />
      <Text size='sm' mt='md'>
        Read our <Anchor onClick={() => setIsTermsModalOpen(true)}>Terms and Conditions</Anchor>
      </Text>
      <Group position='center' mt='lg'>
        <Button
          onClick={handleRegister}
          type='submit'
          fullWidth
          loading={isLoading}
          disabled={!hasAgreedToTerms}
          color='red' // set the color directly
          style={{ marginTop: '1rem' }} // add any additional styling here
        >
          Register
        </Button>
      </Group>
      {/* Terms and Conditions Modal */}
      <Modal opened={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)} title='Terms and Conditions' size='100%'>
        <div style={{ maxHeight: '700px', overflowY: 'scroll' }}>
          <TermsAndConditions />
        </div>
      </Modal>
    </>
  )
}

export default RegisterForm
