import React, { useState, useEffect } from 'react'
import { Container, Paper, Title } from '@mantine/core'
import { useLocation } from 'react-router-dom'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import WaitlistForm from './WaitlistForm'

const AuthForm = () => {
  const location = useLocation()
  const [formType, setFormType] = useState('waitlist')

  const handleLoginSuccess = () => {
    // Logic after successful login
  }

  useEffect(() => {
    if (location.pathname.includes('/auth/register')) {
      setFormType('register')
    } else if (location.pathname.includes('/auth/login')) {
      setFormType('login')
    } else {
      setFormType('waitlist')
    }
  }, [location.pathname])

  const renderForm = () => {
    switch (formType) {
      case 'login':
        return <LoginForm onLoginSuccess={handleLoginSuccess} />
      case 'register':
        return <RegisterForm />
      case 'waitlist':
        return <WaitlistForm />
      default:
        return null
    }
  }

  return (
    <Container size={420} my={40}>
      <Paper withBorder shadow='md' p={30} radius='md'>
        <Title align='center'>
          {formType === 'login' ? 'Welcome back!' : formType === 'register' ? 'Create an account' : 'Join our waitlist'}
        </Title>
        {renderForm()}
      </Paper>
    </Container>
  )
}

export default AuthForm
