import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'

// Define interface for component props
interface ProtectedRouteProps {
  children: ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const auth = getAuth()
  const user = auth.currentUser

  // Redirect to login page if no user is logged in
  if (!user) {
    return <Navigate to='/auth' replace />
  }

  // If user is logged in, return the child components (the protected page content)
  return <>{children}</>
}

export default ProtectedRoute
