import { ComingSoon } from '../../components/ComingSoon/ComingSoon.tsx'
import { Paper, Title } from '@mantine/core'
import { IconPresentationAnalytics } from '@tabler/icons-react'

export default function AccountAnalytics() {
  return (
    <Paper shadow='none' p='md' radius='md'>
      <Title>
        <IconPresentationAnalytics /> Analytics
      </Title>
      <ComingSoon />
    </Paper>
  )
}
