import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

const adsolotColors = ['#fff1e2', '#ffe3cc', '#ffc69b', '#fea766', '#fc8c38', '#fc7b1c', '#fd730b', '#e26200', '#ca5600', '#b04800']

const theme = createTheme({
  primaryColor: 'adsolot',
  fontFamily: 'montserrat',
  colors: {
    adsolot: adsolotColors
  }
})

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <Notifications position='top-center' />
      <App />
    </MantineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
