import { db } from './firebase-config'
import {
  collection,
  addDoc,
  setDoc,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  updateDoc,
  arrayUnion,
  deleteDoc,
  arrayRemove
} from 'firebase/firestore'

// Function to add a new user
export const addUser = async (userId, firstName, lastName, email) => {
  try {
    // Use `setDoc` with a `doc` reference to create a new document with a specific ID
    const userRef = doc(db, 'users', userId) // Create a reference to the document with the UID
    await setDoc(userRef, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      agentIds: [] // Initialize the agentIds array
    })
    console.log('User added with ID: ', userId)
  } catch (e) {
    console.error('Error adding user: ', e)
  }
}

// Function to delete a project
export const deleteAgentProject = async (userId, projectId) => {
  const projectDocRef = doc(db, 'agentProjects', projectId)
  await deleteDoc(projectDocRef)

  // Remove the projectId from the user's agentIds array
  const userDocRef = doc(db, 'users', userId)
  await updateDoc(userDocRef, {
    agentIds: arrayRemove(projectId)
  })
}

// Function to add a user to the waitlist
export const addToWaitlist = async (firstName, lastName, email, gptLink) => {
  try {
    // Add the gptLink to the document if it's provided, otherwise do not include it in the document
    const waitlistDoc = gptLink ? { firstName, lastName, email, gptLink } : { firstName, lastName, email }
    await addDoc(collection(db, 'waitlist'), waitlistDoc)
    console.log('Data added to waitlist')
    // Preparing the body for the POST request to send the confirmation email
    const emailDetails = {
      recipient_email: [email], // Array of emails, in case you want to send to multiple recipients
      subject: 'Welcome to Adsolot!'
      // No body parameter is needed here since it's handled by your FastAPI application
    }
    // Make the API call to your FastAPI endpoint
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/send-email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // Include any other headers your API requires
      },
      body: JSON.stringify(emailDetails)
    })
    if (!response.ok) {
      throw new Error('Failed to send confirmation email')
    }
    console.log('Confirmation email sent successfully')
  } catch (error) {
    console.error('Error adding to waitlist: ', error)
    throw error
  }
}

export const checkEmailExists = async (email) => {
  const q = query(collection(db, 'waitlist'), where('email', '==', email))
  const querySnapshot = await getDocs(q)
  return !querySnapshot.empty
}

// Function to create a new GPT Project
export const addGPTProject = async (userId, projectDetails) => {
  try {
    const docRef = await addDoc(collection(db, 'agentProjects'), {
      userId,
      ...projectDetails
    })
    console.log('Project added with ID: ', docRef.id)
  } catch (e) {
    console.error('Error adding project: ', e)
  }
}

// Function to retrieve all GPT Projects for a user
export const getGPTProjectsByUser = async (userId) => {
  const q = query(collection(db, 'agentProjects'), where('userId', '==', userId))
  const querySnapshot = await getDocs(q)
  const projects = []
  querySnapshot.forEach((doc) => {
    projects.push({ id: doc.id, ...doc.data() })
  })
  return projects
}

// Function to get a user's first name by user ID
export const getUserFirstNameById = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId) // Reference to the user document
    const userDocSnap = await getDoc(userDocRef) // Get the document snapshot

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data() // Data from the document
      console.log(`First Name retrieved for user ID ${userId}:`, userData.firstName)
      return userData.firstName // Return the first name
    } else {
      console.log('No user found with this ID.')
      return null // Handle as needed
    }
  } catch (e) {
    console.error('Error retrieving user first name: ', e)
    throw e // Allow the caller to handle the error
  }
}

// Function to get a user's last name by user ID
export const getUserLastNameById = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId) // Reference to the user document
    const userDocSnap = await getDoc(userDocRef) // Get the document snapshot

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data() // Data from the document
      console.log(`Last Name retrieved for user ID ${userId}:`, userData.lastName)
      return userData.lastName // Return the last name
    } else {
      console.log('No user found with this ID.')
      return null // Handle as needed
    }
  } catch (e) {
    console.error('Error retrieving user last name: ', e)
    throw e // Allow the caller to handle the error
  }
}

export const getUserEmailById = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId) // Reference to the user document
    const userDocSnap = await getDoc(userDocRef) // Get the document snapshot

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data() // Data from the document
      console.log(`Email retrieved for user ID ${userId}:`, userData.email)
      return userData.email // Return the email address
    } else {
      console.log('No user found with this ID.')
      return null // Handle as needed
    }
  } catch (e) {
    console.error('Error retrieving user email: ', e)
    throw e // Allow the caller to handle the error
  }
}

// Function to delete a user from firestore (the database)
export const deleteUserFromFirestore = async (userId) => {
  try {
    await db.collection('users').doc(userId).delete()
    console.log('User document deleted from Firestore')
  } catch (error) {
    console.error('Error deleting user document: ', error)
  }
}

export const addAgentProject = async (userId, projectData, adId) => {
  try {
    // Add a new document with a generated ID in 'agentProjects' collection
    const docRef = await addDoc(collection(db, 'agentProjects'), {
      ...projectData,
      userId,
      adId // Include the adId when creating the agent project
    })
    console.log('Agent Project added with ID:', docRef.id)

    // Additionally, update the user's document with the new agent ID
    const userDocRef = doc(db, 'users', userId)
    await updateDoc(userDocRef, {
      agentIds: arrayUnion(docRef.id)
    })

    return { success: true, agentId: docRef.id }
  } catch (error) {
    console.error('Error adding agent project:', error)
    throw error // Re-throw the error for the caller to handle
  }
}

// Example function to get adId for an agent
export const getAdIdForAgent = async (agentId) => {
  const agentDocRef = doc(db, 'agentProjects', agentId)
  const agentDocSnap = await getDoc(agentDocRef)

  if (agentDocSnap.exists()) {
    const agentData = agentDocSnap.data()
    return agentData.adId // This should return the adId associated with the agent
  } else {
    console.error('No such agent!')
    return null
  }
}

// Example function to get ad details by adId
export const getAdByAdId = async (adId) => {
  const adDocRef = doc(db, 'ads', adId)
  const adDocSnap = await getDoc(adDocRef)

  if (adDocSnap.exists()) {
    return adDocSnap.data() // Return the ad document data
  } else {
    console.error('No such ad!')
    return null // Handle the case where the ad doesn't exist
  }
}

// Function to retrieve a single agent project by its ID
export const getAgentProject = async (projectId) => {
  const projectDocRef = doc(db, 'agentProjects', projectId)
  const projectDocSnap = await getDoc(projectDocRef)

  if (projectDocSnap.exists()) {
    return projectDocSnap.data() // Return the project data
  } else {
    console.error('No such agent project!')
    return null // Handle the case where the project doesn't exist
  }
}
