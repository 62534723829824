import React, { useState } from 'react'
import { TextInput, Button, Group, PasswordInput } from '@mantine/core'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import PropTypes from 'prop-types' // Import PropTypes

const LoginForm = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [_error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const auth = getAuth()

  const handleLogin = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError('')
    try {
      await signInWithEmailAndPassword(auth, email, password)
      onLoginSuccess() // Callback to parent component
      navigate('/projects')
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red'
      })
    }
    setIsLoading(false)
  }

  return (
    <>
      <TextInput label='Email' placeholder='Your Email' required value={email} onChange={(e) => setEmail(e.target.value)} mt='md' />
      <PasswordInput
        label='Password'
        placeholder='Your password'
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        mt='md'
      />
      <Group position='center' mt='lg'>
        <Button
          onClick={handleLogin}
          type='submit'
          fullWidth
          loading={isLoading}
          color='red' // set the color directly
          style={{ marginTop: '1rem' }} // add any additional styling here
        >
          Login
        </Button>
      </Group>
    </>
  )
}

// Define prop types for LoginForm
LoginForm.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired // Define the expected type for onLoginSuccess prop
}

export default LoginForm
