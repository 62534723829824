import React from 'react'
import { Paper, Group } from '@mantine/core'
import './ChatComponent.css' // Make sure the path to your CSS file is correct
// Images for bot
import botImage from '../../assets/ai_icon.png'
import userAvatar from '../../assets/user_avatar.webp'

interface FakeChatComponentProps {
  botName: string
  responseImage: string
  responseImageURL: string
}

export function FakeChatComponent({ botName, responseImage, responseImageURL }: FakeChatComponentProps) {
  const userQuery = 'What is the summary of the next chapter?'
  const botResponse =
    "Chapter 2 of 'Getting Things Done' covers the process of capturing everything that has your attention, including tasks, ideas, notes, and more. It suggests techniques like using lists, tools like Evernote, and setting up routines to manage this information."

  return (
    <div>
      {/* Header */}
      <Paper shadow='lg' className='chat-header'>
        Conversation with {botName}
      </Paper>

      {/* Chat container */}
      <Paper shadow='xs' className='chat-container'>
        <Group direction='column' spacing='xs'>
          {/* User message with avatar */}
          <div className={`message user-message`}>
            <img src={userAvatar} alt='User avatar' className='avatar' />
            <div className='message-content'>
              <strong>User</strong>
              <p className='text'>{userQuery}</p>
            </div>
          </div>

          {/* Response message with avatar and clickable response image */}
          <div className={`message response-message`}>
            <img src={botImage} alt={`${botName} avatar`} className='avatar' />
            <div className='message-content'>
              <strong>{botName}</strong>
              <p className='text'>{botResponse}</p>
              {/* Clickable response image */}
              {responseImage && responseImageURL && (
                <a href={responseImageURL} target='_blank' rel='noopener noreferrer'>
                  <img src={responseImage} alt='Response visual aid' className='response-image' />
                </a>
              )}
            </div>
          </div>
        </Group>
      </Paper>
    </div>
  )
}
