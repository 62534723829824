// These terms and conditions are a palceholder, real terms and conditions have to be aligned with a legal team.

// TermsAndConditions.js
import React from 'react'

//export default BulletPointList;

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', maxHeight: '80vh' }}>
      <h1>Terms and Conditions for Adsolot</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Adsolot, a cutting-edge advertising platform that bridges the gap between innovative content creators using Generative
        Pre-trained Transformers (GPTs) and advertisers seeking to captivate audiences through personalized and interactive advertising. By
        accessing or utilizing the services offered by Adsolot (&quot;Service&quot;), you, whether as a content creator or an advertiser
        (&quot;User&quot;), agree to be bound by these Terms and Conditions (&quot;Terms&quot;).
      </p>
      <p>
        Please read these Terms carefully before using our Service. Your access to and use of the Service is conditioned upon your
        acceptance of and compliance with these Terms. If you disagree with any part of the Terms, then you do not have permission to access
        the Service.
      </p>

      <h2>2. Account Registration and Use</h2>
      <h3>2.1 Eligibility</h3>
      <p>
        To use Adsolot, you must be at least 18 years of age or of the legal age of majority in your jurisdiction, whichever is higher. By
        agreeing to these Terms, you represent and warrant that you are of legal age.
      </p>
      <h3>2.2 Account Security</h3>
      <p>
        Upon creating an account, you are responsible for maintaining the confidentiality of your account information, including your
        password, and for all activities that occur under your account. You agree to notify Adsolot immediately of any unauthorized use of
        your account or any other breach of security.
      </p>

      <h2>3. Content Guidelines and Intellectual Property</h2>
      <h3>3.1 Content Ownership and License</h3>
      <p>
        As a content creator on Adsolot, you retain all rights to the content you create and publish through our Service. However, by
        publishing content on Adsolot, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and
        display your content in connection with the Service.
      </p>
      <h3>3.2 Prohibited Content</h3>
      <p>Users are prohibited from posting content that:</p>
      <ul>
        <li>Is illegal under German law or the laws of the user&apos;s jurisdiction.</li>
        <li>Infringes upon the intellectual property rights of others.</li>
        <li>Contains hate speech, threats, explicit violence, or pornographic material.</li>
        <li>Is intended to harass, abuse, or harm another person.</li>
      </ul>
      <p>
        Adsolot reserves the right, but not the obligation, to review, monitor, or remove User Content at our discretion and without notice
        at any time.
      </p>
      <h2>4. Advertising Policies</h2>
      <p>
        Adsolot provides a platform for advertisers to create and disseminate advertisements (&quot;Ads&quot;). Advertisers are responsible
        for ensuring that their Ads comply with all applicable laws and regulations and do not infringe on the rights of third parties.
        Adsolot reserves the right to reject or remove any Ads that violate these Terms or our Advertising Guidelines, which are available
        on our website.
      </p>

      <h2>5. Data Protection and Privacy</h2>
      <p>
        In compliance with the General Data Protection Regulation (GDPR) and German data protection laws, Adsolot is committed to protecting
        the privacy and security of our users&apos; data. Please refer to our Privacy Policy, which outlines our practices regarding the
        collection, use, and disclosure of personal data when you use our Service.
      </p>

      <h2>6. Pricing, Payments, and Refunds</h2>
      <h3>6.1 Pricing and Payments</h3>
      <p>
        Adsolot operates on a different pricing models. Detailed pricing information is provided on our platform. Payments must be made in
        accordance with the payment terms specified on our platform
      </p>
      <h3>6.2 Refunds</h3>
      <p>
        Refunds are processed in accordance with our Refund Policy, as detailed on our website. Generally, refunds are issued at the
        discretion of Adsolot and may be subject to certain conditions or exclusions.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by applicable law, Adsolot shall not be liable for any indirect, incidental, special, consequential,
        or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from
        (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the
        Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or
        content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been
        informed of the possibility of such damage.
      </p>

      <h2>8. Governing Law and Jurisdiction</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of Germany, without regard to its conflict of law
        provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
        provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in
        effect.
      </p>

      <h2>9. Changes to Terms and Conditions</h2>
      <p>
        Adsolot reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will
        provide at least 30 days&apos; notice prior to any new terms taking effect. What constitutes a material change will be determined at
        our sole discretion.
      </p>

      <h2>10. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at contact@adsolot.com.</p>
    </div>
  )
}

export default TermsAndConditions
