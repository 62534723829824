// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAWimN5q1pu8CRRePtZ3ho0ZZnRTnykpJE',
  authDomain: 'adsolot-19b60.firebaseapp.com',
  projectId: 'adsolot-19b60',
  storageBucket: 'adsolot-19b60.appspot.com',
  messagingSenderId: '184899091862',
  appId: '1:184899091862:web:4c4c9be26fe68f3d06b1b7',
  measurementId: 'G-12K8NX33V2'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export { db }
export default app
