import React, { useEffect, useState } from 'react'
import { getAdByAdId, deleteAgentProject } from '../../firebase/firestoreService'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { Button, Paper, Title } from '@mantine/core'
import PromptBox from './components/PromptBox.tsx'
import { Link } from 'react-router-dom'
import { IconNotes, IconTrashFilled } from '@tabler/icons-react'
import { ComingSoon } from '../../components/ComingSoon/ComingSoon.tsx'
import './ProjectDashboard.module.css'
import { FakeChatComponent } from '../../components/FakeChatComponent/FakeChatComponent.tsx'

const ProjectDashboard = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  // Force the redirect to the login page
  if (!currentUser) {
    navigate('/auth')
  }
  const location = useLocation()
  const project = location.state?.project // Access project from location state
  const [ad, setAd] = useState(null) // Changed from adUrl to ad to store entire ad object

  const handleDeleteProject = async () => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await deleteAgentProject(currentUser.uid, project.id) // Delete the project
        // If the delete was successful, navigate back to the projects page
        navigate('/projects')
      } catch (error) {
        console.error('Error deleting project:', error)
        // Optionally, display an error message to the user
      }
    }
  }

  useEffect(() => {
    if (project?.adId) {
      const fetchAd = async () => {
        const adData = await getAdByAdId(project.adId)
        setAd(adData) // Store the entire adData object
      }

      fetchAd()
    }
  }, [project])

  if (!project) {
    return <div>Loading...</div> // or some other loading indicator
  }

  // Debug log to see all ad data
  console.log(ad)

  return (
    <Paper shadow='none' p='md' radius='md'>
      <div>
        <Title>
          <IconNotes />{' '}
          <Link to='/projects' style={{ textDecoration: 'none', color: 'inherit' }}>
            Projects
          </Link>{' '}
          {'>'} {project.title}
        </Title>

        <h2>Details</h2>
        <p>
          <b>Name:</b> {project.title}
        </p>
        <p>
          <b>Description:</b> {project.description}
        </p>

        <h2>Installation</h2>
        <p>Copy the following prompt at the bottom of your GPT&apos;s or AI Agent&apos;s system instructions:</p>
        <PromptBox project={project}></PromptBox>
        {/* Now you can use ad object to display more ad details */}
        {ad && (
          <>
            <h2>Ad Preview</h2>
            <p>
              The following is a preview of how the ad could look like when a user is using your GPT or AI Agent on a platform like ChatGPT
              or HuggingChat.
            </p>
            <FakeChatComponent botName={project.title} responseImage={ad.imageURL} responseImageURL={ad.ad_link} />
            <p>
              The look and feel of the of the platform will not be affected. The only difference will be that your GPT or AI Agent will
              insert images at the end of responses.
            </p>
          </>
        )}
        <h2>Analytics</h2>
        <ComingSoon></ComingSoon>
        <h2>Configuration</h2>
        <p>Deleting your project will delete all of your data associated to your GPT and/or AI Agent.</p>
        <Button onClick={handleDeleteProject} variant='filled' color='red' leftSection={<IconTrashFilled size={14} />}>
          Delete Project
        </Button>
      </div>
    </Paper>
  )
}

export default ProjectDashboard
