import React from 'react'
import './BackgroundComponent.css'
import PropTypes from 'prop-types'

const BackgroundComponent = ({ children }) => {
  return (
    <div className='background-wrapper'>
      {/* <img src={leftBlob} className="left-blob" alt="Left Blob" />
      <img src={rightBlob} className="right-blob" alt="Right Blob" /> */}
      {children}
    </div>
  )
}

// Adding PropTypes validation for children
BackgroundComponent.propTypes = {
  children: PropTypes.any // Here you accept any type of children
}

export default BackgroundComponent
