import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { getGPTProjectsByUser } from '../../firebase/firestoreService'
import { IconNotes } from '@tabler/icons-react'

import { Paper, Title } from '@mantine/core'

import ProjectsGrid from './components/ProjectsGrid.tsx' // Adjust the path according to your file structure

const ProjectsPage = () => {
  const [projects, setProjects] = useState([])
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  // Handler for creating new project
  const handleCreateProject = () => {
    navigate('/create-project')
  }

  useEffect(() => {
    // Fetch the projects when the component mounts and the currentUser is set
    if (currentUser) {
      const fetchProjects = async () => {
        const userProjects = await getGPTProjectsByUser(currentUser.uid)
        setProjects(userProjects)
      }

      fetchProjects().catch(console.error)
    }
  }, [currentUser])

  console.log(projects)
  return (
    <Paper shadow='none' p='md' radius='md'>
      <Title>
        <IconNotes /> Projects
      </Title>
      <p>Adsolot projects enable you to monetize with advertising the GPTs and AI Agents you created.</p>
      <ProjectsGrid projects={projects} handleCreateProject={handleCreateProject} />
    </Paper>
  )
}

export default ProjectsPage
