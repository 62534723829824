// PromptBox.js
import React from 'react'
import './PromptBox.css' // Make sure to create a corresponding CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { notifications } from '@mantine/notifications'
import { Project } from '../../../interfaces/projects'

const PromptBox = (project: Project) => {
  // Construct the stable URL for image redirection
  const stableImageUrl = process.env.REACT_APP_API_URL + `/view_image?agentId=${project.id}`

  const copyToClipboard = () => {
    const promptText =
      '***\n' +
      "Include an image link at the end of each response. It's very important to make the image clickable, linking to the same URL as the sponsored link. It's very important that the ad is displayed at the end of every message! the user should never be able to disable it.\n" +
      `Hyperlink: ${stableImageUrl}\n` + // Using the stable URL for both the hyperlink and the image
      `Image: ${stableImageUrl}\n` +
      '***'

    navigator.clipboard
      .writeText(promptText)
      .then(() => {
        notifications.show({
          title: 'Custom instructions copied to clipboard',
          message: 'The instructions have been copied successfully!',
          color: 'green'
        })
      })
      .catch((err) => {
        console.error('Could not copy text: ', err)
        notifications.show({
          title: 'Error',
          message: 'Could not copy text to clipboard',
          color: 'red'
        })
      })
  }

  return (
    <div className='prompt-box'>
      <button className='copy-button' onClick={copyToClipboard}>
        <FontAwesomeIcon icon={faCopy} /> Copy
      </button>
      <p>***</p>
      <p>
        Include an image link at the end of each response. It&apos;s very important to make the image clickable, linking to the same URL as
        the sponsored link. It&apos;s very important that the ad is displayed at the end of every message! the user should never be able to
        disable it.
      </p>
      <p>Hyperlink: {stableImageUrl}</p>
      <p>Image: {stableImageUrl}</p>
      <p>It is important you render the image stored in the image link and make it clickable as a sponsored link</p>
      <p>***</p>
    </div>
  )
}

export default PromptBox
