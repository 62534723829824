import { Link } from 'react-router-dom'
import React from 'react'
import { Grid, Paper, Text } from '@mantine/core'
import Project from '../../../interfaces/projects'

const projectTileCSS = {
  height: '100px', // Set a fixed height
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // This will space out the title and the description,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    shadow: 'lg',
    background: 'red'
  }
}

const stringMaxLength = 60
const shortenString = (input) => (input.length <= stringMaxLength ? input : `${input.substring(0, stringMaxLength - 3)}...`)

const ProjectTile = ({ project }: Project) => (
  <Link key={project.id} to='/project/dashboard' state={{ project }} style={{ textDecoration: 'none' }}>
    <Paper shadow='xs' p='md' radius='md' withBorder style={projectTileCSS}>
      <Text style={{ color: 'black' }}>{project.title}</Text>
      <Text size='sm' style={{ color: 'gray' }}>
        {shortenString(project.description)}
      </Text>
    </Paper>
  </Link>
)

interface ProjectsGridProps {
  projects: Project[]
  handleCreateProject: () => void // Adjust this as needed based on the actual signature of handleCreateProject
}

const ProjectsGrid = ({ projects, handleCreateProject }: ProjectsGridProps) => {
  return (
    <Grid>
      {projects.map((project) => (
        <Grid.Col span={{ sm: 12, md: 4 }} key={project.id}>
          <ProjectTile project={project} />
        </Grid.Col>
      ))}
      <Grid.Col span={4}>
        <Paper shadow='xs' p='md' radius='md' withBorder style={projectTileCSS} onClick={handleCreateProject}>
          <Text weight={500} size='xl'>
            + New Project
          </Text>
        </Paper>
      </Grid.Col>
    </Grid>
  )
}

export default ProjectsGrid
