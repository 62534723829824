import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import app from './firebase/firebase-config'

// core styles are required for all packages
import '@mantine/core/styles.css'

// import bg
import BackgroundComponent from './components/BackgroundComponent/BackgroundComponent.js'

// App Shell
import { AppShell, Burger, Group, rem } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { AppRoutes } from './routes/AppRoutes.tsx'

// import component
import { NavbarNested } from './components/NavbarNested/NavbarNested.tsx'
import { Logo } from './components/Logo.js'

function App() {
  const auth = getAuth(app)
  const [_currentUser, setCurrentUser] = useState(null)
  const [opened, { toggle }] = useDisclosure()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setCurrentUser(user)
      } else {
        // No user is signed in.
        setCurrentUser(null)
      }
    })
  }, [])

  return (
    <Router>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: 'sm',
          collapsed: { mobile: !opened }
        }}
        padding='md'
      >
        <AppShell.Header>
          <Group h='100%' px='md'>
            <Burger opened={opened} onClick={toggle} hiddenFrom='sm' size='sm' />
            <Logo style={{ width: rem(120) }} />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p='md'>
          <NavbarNested />
        </AppShell.Navbar>
        <AppShell.Main>
          <BackgroundComponent>
            <AppRoutes />
          </BackgroundComponent>
        </AppShell.Main>
      </AppShell>
    </Router>
  )
}

export default App
