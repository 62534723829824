import { Image, Container, Title, Text, SimpleGrid } from '@mantine/core'
import image from '../../assets/coming_soon.webp'
import classes from './ComingSoon.module.css'

export function ComingSoon() {
  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Coming Soon...</Title>
          <Text c='dimmed' size='lg'>
            {
              "Adsolot is a new product and we're iterating quickly, but we still have not built all of the features we would like to have. We'll update you when this feature is available."
            }
          </Text>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  )
}
