import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

export const getRandomAdId = async () => {
  const adsColRef = collection(db, 'ads')
  const adsSnapshot = await getDocs(adsColRef)
  const adIds = adsSnapshot.docs.map((doc) => doc.id)

  if (adIds.length === 0) {
    return null // Handle case where there are no ads
  }

  // Select a random adId from the list
  const randomIndex = Math.floor(Math.random() * adIds.length)
  return adIds[randomIndex]
}
