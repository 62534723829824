import { Code, ScrollArea } from '@mantine/core'
import { IconNotes, IconPresentationAnalytics, IconPlus } from '@tabler/icons-react'
import { UserButton } from '../UserButton/UserButton.tsx'
import { LinksGroup } from '../NavbarLinksGroup/NavbarLinksGroup.tsx'
import classes from './NavbarNested.module.css'

const mockdata = [
  { label: 'Create New Project', icon: IconPlus, link: '/create-project' },
  {
    label: 'Projects',
    icon: IconNotes,
    initiallyOpened: true,
    link: '/projects'
  },
  { label: 'Analytics', icon: IconPresentationAnalytics, link: '/analytics' }
]

export function NavbarNested() {
  const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />)

  return (
    <nav className={classes.navbar}>
      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}>{links}</div>
      </ScrollArea>
      <Code fw={700}>v0.0.2</Code>
      <div className={classes.footer}>
        <UserButton />
      </div>
    </nav>
  )
}
