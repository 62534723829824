import { Image, Title } from '@mantine/core'
import image from '../assets/logo_no_name.png'
import { Link } from 'react-router-dom'

export function Logo() {
  return (
    <>
      <Link to='/'>
        <Image src={image} style={{ width: '60px' }} />
      </Link>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <Title c='#ff7470'>Adsolot</Title>
      </Link>
    </>
  )
}
