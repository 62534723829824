import { useState, useEffect } from 'react'
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { addUser } from '../firebase/firestoreService' // Adjust the import path as needed

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null) // Add state for the current user
  const [authError, setAuthError] = useState('')
  const [loading, setLoading] = useState(true) // Add state to track loading status

  // Initialize Firebase Auth
  const auth = getAuth()

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false) // Set loading to false once we get the current user
    })

    // Clean up the listener on unmount
    return unsubscribe
  }, [auth])

  const handleRegister = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const user = userCredential.user
      // Add user to Firestore
      await addUser(user.uid, email)
      console.log('User registered and added to Firestore:', user.uid)
      setAuthError('') // Clear any previous error
      return { success: true, user }
    } catch (error) {
      console.error('Error during registration:', error.message)
      setAuthError(error.message)
      return { success: false, error: error.message }
    }
  }

  // You can also add login, logout, and other auth-related functions here

  return {
    currentUser,
    loading,
    handleRegister,
    authError
    // Add any other functions or state variables you want to expose
  }
}

export default useAuth
