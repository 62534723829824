import React, { useState } from 'react'
import useAuth from '../../hooks/useAuth'
import { addAgentProject } from '../../firebase/firestoreService'
import { useNavigate } from 'react-router-dom'
import { getRandomAdId } from '../../components/AdHandler'
import { TextInput, Button, Group, Paper, Title, Container, Textarea } from '@mantine/core'
import './CreateProjectForm.css'
import { notifications } from '@mantine/notifications'
import { IconPlus } from '@tabler/icons-react'

const CreateProjectForm = () => {
  const { currentUser } = useAuth()
  const [title, setTitle] = useState('') // state for title
  const [description, setDescription] = useState('') // state for description
  const [isLoading, setIsLoading] = useState(false) // state for loading status
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setIsLoading(true) // Set loading to true
    e.preventDefault()
    if (!currentUser) {
      console.error('You must be logged in to create a project')
      notifications.show({
        title: 'Error',
        message: 'You must be logged in to create a project',
        color: 'red'
      })
      setIsLoading(false)
      return
    }
    const adId = await getRandomAdId() // Get a random adId
    if (!adId) {
      console.error('No ads available to link')
      notifications.show({
        title: 'Error',
        message: 'No ads available to link',
        color: 'red'
      })
      setIsLoading(false)
      return
    }

    const projectData = { title, description, adId }

    try {
      // Attempt to add the agent project and capture the result
      const result = await addAgentProject(currentUser.uid, projectData, adId)

      // Check the result and navigate if successful
      if (result.success) {
        navigate('/projects')
        notifications.show({
          title: 'Project Created',
          message: 'You have successfully created your project.',
          color: 'none'
        })
      }
    } catch (error) {
      // Handle submission error
      console.error('Error creating agent project:', error)
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red'
      })
      // Display error to the user if needed
    }
    setIsLoading(false) // Set loading to false after operation is complete
  }

  return (
    <Paper shadow='none' p='md' radius='md'>
      <Title>
        <IconPlus /> Create New Project
      </Title>
      <p>To monetize your project with Adsolot, we first need to create a project for the AI Agent or GPT.</p>
      <Container size='sm' style={{ marginTop: '3rem', position: 'relative', zIndex: 1 }}>
        <Paper withBorder shadow='md' p={30} radius={10} mt={30}>
          {' '}
          {/* Adjust transparency here */}
          <form onSubmit={handleSubmit}>
            <TextInput
              label='Name'
              placeholder='Enter the name of your GPT or AI Agent'
              required
              value={title}
              onChange={(event) => setTitle(event.currentTarget.value)}
              style={{ marginBottom: '1rem' }}
            />
            <Textarea
              label='Description'
              placeholder='Enter a description for your GPT or AI Agent'
              required
              value={description}
              onChange={(event) => setDescription(event.currentTarget.value)}
              style={{ marginBottom: '1rem' }}
            />
            <Group justify='center' mt='md'>
              <Button type='submit' loading={isLoading}>
                Create Project
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </Paper>
  )
}

export default CreateProjectForm
