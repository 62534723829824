import React, { useEffect, useState } from 'react'
import { Container, Avatar, Title, Text, Paper, Group, SimpleGrid, Button } from '@mantine/core'
import app from '../../firebase/firebase-config'
import { getAuth, onAuthStateChanged, signOut, deleteUser } from 'firebase/auth'
import { IconLogout, IconTrash } from '@tabler/icons-react'
import classes from './UserPage.module.css'
import { notifications } from '@mantine/notifications'
import { deleteUserFromFirestore, getUserFirstNameById, getUserLastNameById, getUserEmailById } from '../../firebase/firestoreService'
import { useNavigate } from 'react-router-dom'

const UserPage = () => {
  const auth = getAuth(app)
  const [currentUser, setCurrentUser] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false) // state for loading status
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserDetails = async (user) => {
      try {
        const userFirstName = await getUserFirstNameById(user.uid)
        const userLastName = await getUserLastNameById(user.uid)
        const userEmail = await getUserEmailById(user.uid)

        if (userFirstName && userLastName && userEmail) {
          setFirstName(userFirstName)
          setLastName(userLastName)
          setUserEmail(userEmail)
        } else {
          console.error('Unable to fetch user details')
        }
      } catch (error) {
        console.error('Error fetching user details:', error)
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch user details.',
          color: 'red'
        })
      }
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setCurrentUser(user)
        fetchUserDetails(user) // Fetch additional user details
      } else {
        // No user is signed in.
        setCurrentUser(null)
      }
    })
  }, [auth])

  const handleLogout = () => {
    setIsLoading(true)
    signOut(auth)
      .then(() => {
        console.log('User signed out successfully')
        notifications.show({
          title: 'Logout Successful',
          message: 'See you later',
          color: 'none'
        })
        // Optional: Redirect the user to the homepage or login page
      })
      .catch((error) => {
        console.error('Error signing out:', error)
        notifications.show({
          title: 'Error',
          message: 'Error signing out: ' + error,
          color: 'red'
        })
      })
    setIsLoading(true)
  }

  const deleteAuthenticationRecord = async () => {
    try {
      await deleteUser(currentUser)
      console.log('User authentication record deleted')
    } catch (error) {
      console.error('Error deleting user authentication record:', error)
    }
  }

  const confirmDeleteAccount = async () => {
    const confirmed = window.confirm('Are you sure you want to delete your account? This action cannot be undone.')
    if (!confirmed) {
      return
    }

    try {
      await deleteAuthenticationRecord()
      await deleteUserFromFirestore(currentUser.uid)
      console.log('Account successfully deleted.')
      navigate('/') // Adjust this according to your routing
    } catch (error) {
      console.error('Error deleting account:', error)
    }
  }

  return (
    <Container size='sm' my={40}>
      <Paper withBorder shadow='md' p={30} radius={10} mt={30}>
        <Group justify='center'>
          <Avatar size={120} radius={120} />
        </Group>
        <Title align='center' mt='md'>
          Account
        </Title>
        <Text color='dimmed' align='center' size='lg' style={{ fontWeight: 500 }}>
          {firstName && lastName ? `${firstName} ${lastName}` : 'Loading...'}
        </Text>
        <Text color='dimmed' align='center'>
          {userEmail ? `${userEmail}` : ''}
        </Text>
        <SimpleGrid cols={1} mt='md'>
          <Text size='sm' align='center'>
            AI Creator
          </Text>
        </SimpleGrid>
        <Button fullWidth loading={isLoading} size='md' leftSection={<IconLogout />} className={classes.logoutBtn} onClick={handleLogout}>
          Logout
        </Button>

        <Button fullWidth size='md' leftSection={<IconTrash />} color='red' className={classes.logoutBtn} onClick={confirmDeleteAccount}>
          Delete Account
        </Button>
      </Paper>
    </Container>
  )
}

export default UserPage
