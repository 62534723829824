import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from '../components/ProtectedRoute.tsx'
import ProjectsPage from '../pages/Projects/ProjectsPage'
import AuthForm from '../pages/UserAuth/components/AuthForm'
import CreateProjectForm from '../pages/CreateProject/CreateProjectForm'
import ProjectDashboard from '../pages/ProjectDashboard/ProjectDashboard'
import AccountAnalytics from '../pages/Analytics/AccountAnalytics.tsx'
import UserPage from '../pages/UserPage/UserPage.tsx'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/auth' element={<AuthForm />} />
      <Route path='/auth/register' element={<AuthForm />} />
      <Route path='/auth/login' element={<AuthForm />} />
      <Route
        path='/projects'
        element={
          <ProtectedRoute>
            <ProjectsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/create-project'
        element={
          <ProtectedRoute>
            <CreateProjectForm />
          </ProtectedRoute>
        }
      />
      <Route path='/project/dashboard' element={<ProjectDashboard />} />
      <Route
        path='/analytics'
        element={
          <ProtectedRoute>
            <AccountAnalytics />
          </ProtectedRoute>
        }
      />
      <Route
        path='/account'
        element={
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        }
      />
      <Route path='/' element={<AuthForm />} />
    </Routes>
  )
}
