import React, { useState } from 'react'
import { TextInput, Button, Group, Checkbox, Modal, Text, Anchor, Textarea } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { addToWaitlist, checkEmailExists } from '../../../firebase/firestoreService'
import TermsAndConditions from './../../../components/TermsAndConditions/TermsAndConditions'

const WaitlistForm = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [gptLink, setGptLink] = useState('')
  const [_error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [consentGiven, setConsentGiven] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false) // State for the terms modal

  const handleWaitlist = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError('')

    const emailExists = await checkEmailExists(email)

    if (!firstName || !lastName || !email) {
      notifications.show({
        title: 'Missing Information',
        message: 'Please enter all fields.',
        color: 'red'
      })
    } else if (!consentGiven) {
      notifications.show({
        title: 'Missing Consent',
        message: 'Please consent to the privacy policy.',
        color: 'red'
      })
    } else if (emailExists) {
      notifications.show({
        title: 'Email Already Exists',
        message: 'The email address is already on the waitlist.',
        color: 'red'
      })
    } else {
      try {
        await addToWaitlist(firstName, lastName, email, gptLink)
        notifications.show({
          title: 'Waitlist Success',
          message: 'You have been added to the waitlist.',
          color: 'green'
        })
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: error.message,
          color: 'red'
        })
      }
    }
    setFirstName('')
    setLastName('')
    setEmail('')
    setGptLink('')
    setIsLoading(false)
  }

  return (
    <>
      <TextInput
        className='authInput'
        label='First Name'
        placeholder='Your first name'
        required
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        mt='md' // margin-top for spacing
      />
      <TextInput
        className='authInput'
        label='Last Name'
        placeholder='Your last name'
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        mt='md' // margin-top for spacing
      />
      <TextInput
        className='authInput'
        label='Email'
        placeholder='Your Email'
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        mt='md' // margin-top for spacing
      />
      <Textarea
        label='GPT Links (optional)'
        placeholder='Link to your GPT'
        autosize // makes the textarea grow automatically with input
        minRows={1} // minimum number of visible rows
        value={gptLink}
        onChange={(e) => setGptLink(e.target.value)}
        mt='md' // margin-top for spacing
      />
      <Checkbox
        label={
          <Text size='sm'>
            {
              'I consent to Adsolot collecting and storing the data I submit through this form for the purposes of creating and managing my waitlist account, in accordance with'
            }
            <Anchor onClick={() => setIsTermsModalOpen(true)}> {"Adsolot's Privacy Policy"}</Anchor>.{' '}
            {/* No need to escape inside a string */}
          </Text>
        }
        checked={consentGiven}
        onChange={(event) => setConsentGiven(event.currentTarget.checked)}
        mt='md'
      />
      <Group position='center' mt='lg'>
        <Button
          onClick={handleWaitlist}
          type='submit'
          fullWidth
          loading={isLoading}
          disabled={!consentGiven}
          color='red' // set the color directly
          style={{ marginTop: '1rem' }} // add any additional styling here
        >
          Join Waitlist
        </Button>
      </Group>
      <Modal opened={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)} title='Terms and Conditions' size='100%'>
        <div style={{ maxHeight: '700px', overflowY: 'scroll' }}>
          <TermsAndConditions />
        </div>
      </Modal>
    </>
  )
}

export default WaitlistForm
