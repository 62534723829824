import { UnstyledButton, Group, Avatar, Text, rem } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import classes from './UserButton.module.css'
import useAuth from '../../hooks/useAuth'
import { Link } from 'react-router-dom'

export function UserButton() {
  const { currentUser } = useAuth()

  return (
    <UnstyledButton component={Link} to='/account' className={classes.user}>
      {currentUser && (
        // if user is logged in
        <Group>
          <Avatar radius='xl' />

          <div style={{ flex: 1 }}>
            <Text size='sm' fw={500}>
              {currentUser.email}
            </Text>

            <Text c='dimmed' size='xs'>
              AI Creator
            </Text>
          </div>

          <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
        </Group>
      )}
    </UnstyledButton>
  )
}
